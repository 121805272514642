import React, { useEffect, useState } from "react";
import {
	URL_COUNTRY_LIST,
	URL_CITY_LIST,
	URL_REGISTER_TYPE_LIST,
	URL_REGISTER_FORM_GENERAL,
} from "../common/urlAPI";
import { Infinity200px } from "../common/urlImages";

export default function Register() {
	const [err, setError] = useState("");
	const [msg, setMsg] = useState("");

	const [loading, setLoader] = useState(false);

	const [countryList, setCountryList] = useState([]);
	const [cityList, setCityList] = useState([]);
	const [registerTypeList, setRegisterTypeList] = useState([]);

	const [countryID, setCountryID] = useState(null);
	const [cityID, setCityID] = useState(null);
	const [registerTypeID, setRegisterTypeID] = useState(null);
	const [nationalityID, setNationalityID] = useState(null);
	const [genderID, setGenderID] = useState(null);

	const [formData, setFormData] = useState({
		id: 1,
		firstName: "",
		secondName: "",
		thirdName: "",
		forthName: "",
		registrationTypeID: null,
		commercialName: "",
		birthDate: "",
		gender: "",
		nationalityID: null,
		countryID: null,
		cityID: null,
		address: "",
		phone1: "",
		phone2: "",
		email: "",
		whyJoining: "",
	});

	useEffect(() => {
		GetCountryData();
		GetRegisterTypeData();

		window.selectActivate("CountryID", handleOnSelectionCountry);
		window.selectActivate("CityID", handleOnSelectionCity);
		window.selectActivate(
			"RegistrationTypeID",
			handleOnSelectionRegistrationType
		);
		window.selectActivate("NationalityID", handleOnSelectionNationality);
		window.selectActivate("Gender", handleOnSelectionGender);
	}, []);

	useEffect(() => {}, [countryList]);

	const GetCountryData = async (_e) => {
		var requestHeader = new Headers();
		requestHeader.append("Accept", "application/json");
		requestHeader.append("Content-Type", "application/json;charset=UTF-8");
		try {
			const response = await fetch(URL_COUNTRY_LIST, {
				method: "GET",
				headers: requestHeader,
			});
			if (response.ok) {
				const result = await response.json();
				setCountryList(result);
			}
		} catch {}
	};

	const GetRegisterTypeData = async (_e) => {
		var requestHeader = new Headers();
		requestHeader.append("Accept", "application/json");
		requestHeader.append("Content-Type", "application/json;charset=UTF-8");
		try {
			const response = await fetch(URL_REGISTER_TYPE_LIST, {
				method: "GET",
				headers: requestHeader,
			});
			if (response.ok) {
				const result = await response.json();
				setRegisterTypeList(result);
			}
		} catch {}
	};

	const handleOnSelectionCountry = async (e) => {
		setCountryID(e.target.value);
		var requestHeader = new Headers();
		requestHeader.append("Accept", "application/json");
		requestHeader.append("Content-Type", "application/json;charset=UTF-8");
		try {
			const response = await fetch(URL_CITY_LIST + "?id=" + e.target.value, {
				method: "GET",
				headers: requestHeader,
			});

			if (response.ok) {
				const result = await response.json();
				setCityList(result);
			}
		} catch {}
	};

	const handleOnSelectionGender = (e) => {
		setGenderID(e.target.value);
	};

	const handleOnSelectionNationality = (e) => {
		setNationalityID(e.target.value);
	};

	const handleOnSelectionCity = (e) => {
		setCityID(e.target.value);
	};

	const handleOnSelectionRegistrationType = (e) => {
		setRegisterTypeID(e.target.value);
	};

	const changeHandler = (e) => {
		setFormData({ ...formData, [e.target.id]: e.target.value });
		//setError("");
	};

	function validateInput() {
		if (genderID === null || genderID === "") {
			setError("يجب إدخال النوع الاجتماعي");
			return false;
		}
		if (countryID === null || countryID === "") {
			setError("يجب إدخال دولة المقر");
			return false;
		}
		if (cityID === null || cityID === "") {
			setError("يجب إدخال مدينة المقر");
			return false;
		}
		if (registerTypeID === null || registerTypeID === "") {
			setError("يجب إدخال نوع التسجيل");
			return false;
		}
		if (nationalityID === null || nationalityID === "") {
			setError("يجب إدخال الجنسية");
			return false;
		}
		if (formData.firstName.length <= 3) {
			setError("يجب إدخال الاسم الأول");
			return false;
		}
		if (formData.forthName.length <= 3) {
			setError("يجب إدخال الاسم الرابع");
			return false;
		}
		if (formData.birthDate.length <= 5) {
			setError("يجب إدخال تاريخ الميلاد");
			return false;
		}
		if (formData.address.length <= 3) {
			setError("يجب إدخال العنوان المقر");
			return false;
		}
		if (formData.phone1.length <= 5) {
			setError("يجب إدخال الهاتف المحمول");
			return false;
		}
		if (formData.phone2.length <= 5) {
			setError("يجب إدخال الهاتف الثابت");
			return false;
		}
		if (formData.email.length <= 5) {
			setError("يجب إدخال البريد الإلكتروني");
			return false;
		}
		if (formData.whyJoining.length <= 5) {
			setError("يجب إدخال لماذا تريد الانضمام إلينا");
			return false;
		}
		return true;
	}

	const RegisterForm = async (_e) => {
		if (!validateInput()) {
			return;
		}
		if (loading) {
			return;
		}

		setLoader(true);
		var requestHeader = new Headers();
		requestHeader.append("Accept", "application/json");
		requestHeader.append("Content-Type", "application/json;charset=UTF-8");

		const params = {
			id: 1,
			firstName: formData.firstName,
			secondName: formData.secondName,
			thirdName: formData.thirdName,
			forthName: formData.forthName,
			registrationTypeID: registerTypeID,
			commercialName: formData.commercialName,
			birthDate: formData.birthDate,
			gender: genderID,
			nationalityID: nationalityID,
			countryID: countryID,
			cityID: cityID,
			address: formData.address,
			phone1: formData.phone1,
			phone2: formData.phone2,
			email: formData.email,
			whyJoining: formData.whyJoining,
		};

		try {
			const response = await fetch(URL_REGISTER_FORM_GENERAL, {
				method: "POST",
				headers: requestHeader,
				body: JSON.stringify(params),
			});

			if (response.ok) {
				const result = await response.json();
				setMsg("تم التسجيل بنجاح؛ سيتم التواصل معكم");
			} else {
				const result = await response.json();
				setError("هناك مشكلة في المدخلات" + JSON.stringify(result));
				setLoader(false);
			}
		} catch {
			setError("هناك خطأ بتسجيل البيانات!");
			setLoader(false);
		}
	};
	return (
		<>
			<main className="main">
				<div className="container mb-80 mt-50">
					<div className="row">
						<div className="col-lg-8 mb-40">
							<h1 className="heading-2 mb-10">تسجيل حساب</h1>
							<div className="d-flex justify-content-between">
								<h6 className="text-body">الانضمام لعائلة ياتاجر</h6>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="row">
								<h4 className="mb-30">تفاصيل الحساب</h4>
								{err && <h4 className="mb-30 text-danger">{err}</h4>}
								{msg && <h4 className="mb-30 text-success">{msg}</h4>}
								<div className="row">
									<div className="form-group form-background col-lg-3">
										<label htmlFor="firstName">الاسم الاول *</label>
										<input
											type="text"
											required=""
											id="firstName"
											placeholder="الاسم الأول *"
											onChange={changeHandler}
										/>
									</div>
									<div className="form-group form-background col-lg-3">
										<label htmlFor="secondName">الاسم الثاني</label>
										<input
											type="text"
											id="secondName"
											placeholder="الاسم الثاني"
											onChange={changeHandler}
										/>
									</div>
									<div className="form-group form-background col-lg-3">
										<label htmlFor="thirdName">الاسم الثالث</label>
										<input
											type="text"
											id="thirdName"
											placeholder="الاسم الثالث"
											onChange={changeHandler}
										/>
									</div>
									<div className="form-group form-background col-lg-3">
										<label htmlFor="forthName">اسم العائلة *</label>
										<input
											type="text"
											required=""
											id="forthName"
											placeholder="الاسم العائلة *"
											onChange={changeHandler}
										/>
									</div>
								</div>
								<div className="row shipping_calculator">
									<div className="form-group col-lg-3">
										<label htmlFor="RegistrationTypeID">نوع التسجيل *</label>
										<div className="custom_select">
											<select
												id="RegistrationTypeID"
												className="form-control select-active"
											>
												<option value="">اختر نوع ...</option>
												{registerTypeList &&
													registerTypeList.map((item) => (
														<option key={item.id} value={item.id}>
															{item.title}
														</option>
													))}
											</select>
										</div>
									</div>
									<div className="form-group form-background col-lg-3">
										<label htmlFor="commercialName">الاسم التجاري</label>
										<input
											type="text"
											required=""
											id="commercialName"
											placeholder="الاسم التجاري"
											onChange={changeHandler}
										/>
									</div>
									<div className="form-group form-background col-lg-3">
										<label htmlFor="birthDate">تاريخ الميلاد *</label>
										<input
											type="date"
											required=""
											id="birthDate"
											placeholder="تاريخ الميلاد *"
											onChange={changeHandler}
										/>
									</div>
									<div className="form-group col-lg-3">
										<label htmlFor="Gender">النوع الاجتماعي *</label>
										<div className="custom_select">
											<select
												id="Gender"
												className="form-control select-active"
											>
												<option value="">اختر النوع الاجتماعي...</option>
												<option value="1">ذكر</option>
												<option value="2">انثى</option>
											</select>
										</div>
									</div>
								</div>
								<div className="row shipping_calculator">
									<div className="form-group form-background col-lg-3">
										<label htmlFor="NationalityID">الجنسية *</label>
										<div className="custom_select">
											<select
												id="NationalityID"
												className="form-control select-active"
											>
												<option value="">اختر الجنسية...</option>
												{countryList &&
													countryList.map((item) => (
														<option key={item.id} value={item.id}>
															{item.title}
														</option>
													))}
											</select>
										</div>
									</div>
									<div className="form-group col-lg-3">
										<label>الدولة *</label>
										<div className="custom_select">
											<select
												id="CountryID"
												className="form-control select-active"
											>
												<option value="">اختر الدولة...</option>
												{countryList &&
													countryList.map((item) => (
														<option key={item.id} value={item.id}>
															{item.title}
														</option>
													))}
											</select>
										</div>
									</div>
									<div className="form-group form-background col-lg-3">
										<label htmlFor="CityID">المدينة *</label>
										<div className="custom_select">
											<select
												id="CityID"
												className="form-control select-active"
											>
												<option value="">اختر المدينة...</option>
												{cityList &&
													cityList.map((item) => (
														<option key={item.id} value={item.id}>
															{item.title}
														</option>
													))}
											</select>
										</div>
									</div>
									<div className="form-group form-background col-lg-3">
										<label htmlFor="address">العنوان *</label>
										<input
											type="text"
											required=""
											id="address"
											placeholder="العنوان *"
											onChange={changeHandler}
										/>
									</div>
								</div>
								<div className="row">
									<div className="form-group form-background col-lg-4">
										<label htmlFor="phone1">الهاتف المحمول *</label>
										<input
											type="text"
											id="phone1"
											required=""
											placeholder="الهاتف المحمول *"
											onChange={changeHandler}
										/>
									</div>
									<div className="form-group form-background col-lg-4">
										<label htmlFor="phone2">الهاتف الثابت</label>
										<input
											type="text"
											id="phone2"
											placeholder="الهاتف الثابت"
											onChange={changeHandler}
										/>
									</div>
									<div className="form-group form-background col-lg-4">
										<label htmlFor="email">البريد الإلكتروني *</label>
										<input
											type="email"
											id="email"
											required=""
											placeholder="البريد الإلكتروني *"
											onChange={changeHandler}
										/>
									</div>
								</div>
								<div className="form-group form-background mb-30">
									<label htmlFor="whyJoining">
										لماذا تريد الانضمام لعائلة ياتاجر *
									</label>
									<textarea
										rows="5"
										id="whyJoining"
										placeholder="لماذا تريد الانضمام إلينا"
										onChange={changeHandler}
									></textarea>
								</div>
								<button
									className="btn btn-fill-out btn-block mt-30"
									onClick={RegisterForm}
								>
									{loading === false ? (
										"طلب تسجيل"
									) : (
										<img
											src={Infinity200px}
											style={{ height: "1.5rem" }}
											alt="-"
										/>
									)}
								</button>
							</div>
						</div>
						<div className="col-lg-5"></div>
					</div>
				</div>
			</main>
		</>
	);
}
