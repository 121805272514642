import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
	return (
		<>
			<div className="mobile-promotion">
				<span>
					Grand opening, <strong>up to 15%</strong> off all items. Only{" "}
					<strong>3 days</strong> left
				</span>
			</div>
			<div className="header-top header-top-ptb-1 d-none d-lg-block">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-xl-3 col-lg-4">
							<div className="header-info">
								<ul>
									<li>
										<Link to="/about">من نحن</Link>
									</li>
									<li>
										<Link to="/myaccount">حسابي</Link>
									</li>
									<li>
										<Link to="/contactus">الاتصال بنا</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-6 col-lg-4">
							<div className="text-center">
								<div id="news-flash" className="d-inline-block">
									<ul>
										<li>100% توصيل مجاني</li>
										<li>وفر الكثير عند استخدام الكوبونات</li>
										<li>عرض الموسم بتخفيض يصل إلى 40%</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-4">
							<div className="header-info header-info-right">
								<ul>
									<li>
										تحتاج للمساعدة؟ اتصل :
										<strong className="text-brand">
											<a href="tel:101062030" className="text-brand">
												101062030
											</a>
										</strong>
									</li>
									<li>
										<a
											className="language-dropdown-active"
											href="#"
											style={{ fontSize: "10pt" }}
										>
											ريال يمني قديم <i className="fi-rs-angle-small-down"></i>
										</a>
										<ul
											className="language-dropdown"
											style={{ minWidth: "160px", fontSize: "10pt" }}
										>
											<li>
												<a href="#">
													<img
														src="/imgs/flags/ye.svg"
														alt=""
														style={{ marginLeft: "10px" }}
													/>
													ريال يمني جديد
												</a>
											</li>
											<li>
												<a href="#">
													<img
														src="/imgs/flags/us.svg"
														alt=""
														style={{ marginLeft: "10px" }}
													/>
													دولار أمريكي
												</a>
											</li>
											<li>
												<a href="#">
													<img
														src="/imgs/flags/sa.svg"
														alt=""
														style={{ marginLeft: "10px" }}
													/>
													ريال سعودي
												</a>
											</li>
											<li>
												<a href="#">
													<img
														src="/imgs/flags/eg.svg"
														alt=""
														style={{ marginLeft: "10px" }}
													/>
													جنيه مصري
												</a>
											</li>
											<li>
												<a href="#">
													<img
														src="/imgs/flags/ae.svg"
														alt=""
														style={{ marginLeft: "10px" }}
													/>
													درهم أماراتي
												</a>
											</li>
											<li>
												<a href="#">
													<img
														src="/imgs/flags/om.svg"
														alt=""
														style={{ marginLeft: "10px" }}
													/>
													ريال عماني
												</a>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="header-middle header-middle-ptb-1 d-none d-lg-block">
				<div className="container">
					<div className="header-wrap">
						<div className="logo logo-width-1">
							<a href="/">
								<img
									src="/logo.png"
									style={{ width: "180px", height: "78px" }}
									alt="logo"
								/>
							</a>
						</div>
						<div className="header-right">
							<div className="search-style-2">
								<form action="#">
									<select className="select-active">
										<option>كل الأقسام</option>
										<option>الحليب ومشتقاته</option>
										<option>الخضروات</option>
										<option>البقوليات</option>
										<option>اللحوم</option>
										<option>المعجنات</option>
										<option>الاساسية</option>
										<option>العلاجية</option>
										<option>الاسماك ومشتقاته</option>
										<option>الحلويات</option>
										<option>منتجات الأطفال</option>
									</select>
									<input type="text" placeholder="البحث عن أصناف..." />
								</form>
							</div>
							<div className="header-action-right">
								<div className="header-action-2">
									<div className="header-action-icon-2">
										<a>
											<img
												className="svgInject"
												alt="Nest"
												src="/imgs/theme/icons/icon-heart.svg"
											/>
											<span className="pro-count blue">6</span>
										</a>
										<a>
											<span className="lable">المفضلة</span>
										</a>
									</div>
									{/* <div className="header-action-icon-2">
										<a className="mini-cart-icon">
											<img alt="Nest" src="/imgs/theme/icons/icon-cart.svg" />
											<span className="pro-count blue">2</span>
										</a>
										<a>
											<span className="lable">السلة</span>
										</a>
										<div className="cart-dropdown-wrap cart-dropdown-hm2">
											<ul>
												<li>
													<div className="shopping-cart-img">
														<a href="#">
															<img
																alt="Nest"
																src="/imgs/shop/thumbnail-3.jpg"
															/>
														</a>
													</div>
													<div className="shopping-cart-title">
														<h4>
															<a href="#">Daisy Casual Bag</a>
														</h4>
														<h4>
															<span>1 × </span>$800.00
														</h4>
													</div>
													<div className="shopping-cart-delete">
														<a href="#">
															<i className="fi-rs-cross-small"></i>
														</a>
													</div>
												</li>
												<li>
													<div className="shopping-cart-img">
														<a href="#">
															<img
																alt="Nest"
																src="/imgs/shop/thumbnail-2.jpg"
															/>
														</a>
													</div>
													<div className="shopping-cart-title">
														<h4>
															<a href="#">Corduroy Shirts</a>
														</h4>
														<h4>
															<span>1 × </span>$3200.00
														</h4>
													</div>
													<div className="shopping-cart-delete">
														<a href="#">
															<i className="fi-rs-cross-small"></i>
														</a>
													</div>
												</li>
											</ul>
											<div className="shopping-cart-footer">
												<div className="shopping-cart-total">
													<h4>
														Total <span>$4000.00</span>
													</h4>
												</div>
												<div className="shopping-cart-button">
													<a href="#" className="outline">
														View cart
													</a>
													<a href="#">Checkout</a>
												</div>
											</div>
										</div>
									</div> */}
									<div className="header-action-icon-2">
										<Link to="/register">
											<img
												className="svgInject"
												alt="Nest"
												src="/imgs/theme/icons/icon-user.svg"
											/>
											<span className="lable">تسجيل حساب</span>
										</Link>
									</div>
									<div className="header-action-icon-2">
										<Link to="/login">
											<img
												className="svgInject"
												alt="Nest"
												src="/imgs/theme/icons/icon-user.svg"
											/>
											<span className="lable">تسجيل دخول</span>
										</Link>
									</div>
									{/* <div className="header-action-icon-2">
										<a href="#">
											<img
												className="svgInject"
												alt="Nest"
												src="/imgs/theme/icons/icon-user.svg"
											/>
										</a>
										<a href="#">
											<span className="lable ml-0">الحساب</span>
										</a>
										<div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
											<ul>
												<li>
													<a href="#">
														<i className="fi fi-rs-user mr-10"></i>لوحة التحكم
													</a>
												</li>
												<li>
													<a href="#">
														<i className="fi fi-rs-location-alt mr-10"></i>
														تتبع طلب
													</a>
												</li>
												<li>
													<a href="#">
														<i className="fi fi-rs-label mr-10"></i>طلباتي
													</a>
												</li>
												<li>
													<a href="#">
														<i className="fi fi-rs-heart mr-10"></i>المفضلة
													</a>
												</li>
												<li>
													<a href="#">
														<i className="fi fi-rs-settings-sliders mr-10"></i>
														الإعدادات
													</a>
												</li>
												<li>
													<a asp-controller="Home" asp-action="Logout">
														<i className="fi fi-rs-sign-out mr-10"></i>تسجيل
														خروج
													</a>
												</li>
											</ul>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
