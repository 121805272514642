import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFound from "./pages/notFound";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import { RouteData } from "./common/RouteData";
import MobileMenu from "./components/menu/mobilemenu";
import Menu from "./components/menu/menu";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<>
		<Router>
			{/* <!-- Modal --> */}
			<div
				className="modal fade custom-modal"
				id="onloadModal"
				tabIndex="-1"
				aria-labelledby="onloadModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
						<div className="modal-body">
							<div
								className="deal"
								style={{ backgroundImage: "url('/imgs/banner/banner-11.png')" }}
							>
								<div className="deal-top">
									<h6 className="mb-10 text-brand-2">Deal of the Day</h6>
								</div>
								<div className="deal-content detail-info">
									<h4 className="product-title">
										<a href="#" className="text-heading">
											Organic goods for your customer's health
										</a>
									</h4>
									<div className="clearfix product-price-cover"></div>
								</div>
								<div className="deal-bottom">
									<p className="mb-20">Hurry Up! Offer End In:</p>
									<div
										className="deals-countdown pl-5"
										data-countdown="2024/09/02 00:00:00"
									>
										<span className="countdown-section">
											<span className="countdown-amount hover-up">03</span>
											<span className="countdown-period"> days </span>
										</span>
										<span className="countdown-section">
											<span className="countdown-amount hover-up">02</span>
											<span className="countdown-period"> hours </span>
										</span>
										<span className="countdown-section">
											<span className="countdown-amount hover-up">43</span>
											<span className="countdown-period"> mins </span>
										</span>
										<span className="countdown-section">
											<span className="countdown-amount hover-up">29</span>
											<span className="countdown-period"> sec </span>
										</span>
									</div>
									<a href="#" className="btn hover-up">
										Shop Now <i className="fi-rs-arrow-right"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<header className="header-area header-style-1 header-height-2">
				<Header />
				<Menu />
			</header>
			<MobileMenu />
			{/* <!--End header--> */}
			<Routes>
				{RouteData.map((idx) => (
					<Route key={idx.id} path={idx.path} element={idx.element} />
				))}
				<Route path="*" element={<NotFound />} />
			</Routes>
			<Footer />
		</Router>
	</>
);
