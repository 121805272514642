import Register from "../pages/register";
import Login from "../pages/login";
import MainPart from "../components/mainsection/mainpart";
import About from "../pages/about";

export const RouteData = [
	{
		id: 1,
		path: `/`,
		element: <MainPart />,
	},
	{
		id: 2,
		path: `/login`,
		element: <Login />,
	},
	{
		id: 3,
		path: `/register`,
		element: <Register />,
	},
	{
		id: 4,
		path: `/about`,
		element: <About />,
	},
];
