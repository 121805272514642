import React from "react";

export default function Footer() {
	return (
		<>
			<footer className="main">
				<section className="newsletter mb-15 wow animate__animated animate__fadeIn">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="position-relative newsletter-inner">
									<div className="newsletter-content">
										<h2 className="mb-20">
											ابقى في مكانك واحصل على كل طلباتك اليومية <br />
											من محلاتنا
										</h2>
										<p className="mb-45">
											ابدأ رحلة تسوقك مع{" "}
											<span className="text-brand">ياتاجر</span>
										</p>
										<form className="form-subcriber d-flex">
											<input
												type="email"
												placeholder="البريد الإلكتروني الخاص بك"
											/>
											<button className="btn" type="submit">
												اشتراك
											</button>
										</form>
									</div>
									<img src="/imgs/banner/banner-9.png" alt="newsletter" />
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="featured section-padding">
					<div className="container">
						<div className="row">
							<div className="col-lg-1-5 col-md-4 col-12 col-sm-6 mb-md-4 mb-xl-0">
								<div
									className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp"
									data-wow-delay="0"
								>
									<div className="banner-icon">
										<img src="/imgs/theme/icons/icon-1.svg" alt="" />
									</div>
									<div className="banner-text">
										<h3 className="icon-box-title">افضل العروض والأسعار</h3>
										<p>أطلب بـ 50$ أو اكثر</p>
									</div>
								</div>
							</div>
							<div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
								<div
									className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp"
									data-wow-delay=".1s"
								>
									<div className="banner-icon">
										<img src="/imgs/theme/icons/icon-2.svg" alt="" />
									</div>
									<div className="banner-text">
										<h3 className="icon-box-title">التوصيل المجاني</h3>
										<p>خدمة على مدار الساعة</p>
									</div>
								</div>
							</div>
							<div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
								<div
									className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp"
									data-wow-delay=".2s"
								>
									<div className="banner-icon">
										<img src="/imgs/theme/icons/icon-3.svg" alt="" />
									</div>
									<div className="banner-text">
										<h3 className="icon-box-title">افضل العروض اليومية</h3>
										<p>اشترك في القناة</p>
									</div>
								</div>
							</div>
							<div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
								<div
									className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp"
									data-wow-delay=".3s"
								>
									<div className="banner-icon">
										<img src="/imgs/theme/icons/icon-4.svg" alt="" />
									</div>
									<div className="banner-text">
										<h3 className="icon-box-title">خيارات متعددة</h3>
										<p>اسعار افضل</p>
									</div>
								</div>
							</div>
							<div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
								<div
									className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp"
									data-wow-delay=".4s"
								>
									<div className="banner-icon">
										<img src="/imgs/theme/icons/icon-5.svg" alt="" />
									</div>
									<div className="banner-text">
										<h3 className="icon-box-title">سهولة الإرجاع</h3>
										<p>خلال 14 يوماً</p>
									</div>
								</div>
							</div>
							<div className="col-lg-1-5 col-md-4 col-12 col-sm-6 d-xl-none">
								<div
									className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp"
									data-wow-delay=".5s"
								>
									<div className="banner-icon">
										<img src="/imgs/theme/icons/icon-6.svg" alt="" />
									</div>
									<div className="banner-text">
										<h3 className="icon-box-title">تسليم آمن</h3>
										<p>خلال 3 ايام فقط</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section-padding footer-mid">
					<div className="container pt-15 pb-20">
						<div className="row">
							<div className="col">
								<div
									className="widget-about font-md mb-md-3 mb-lg-3 mb-xl-0 wow animate__animated animate__fadeInUp"
									data-wow-delay="0"
								>
									<div className="logo mb-30">
										<a href="/" className="mb-15">
											<img
												src="/logo.png"
												style={{ width: "215px", height: "100px" }}
												alt="logo"
											/>
										</a>
										<p className="font-lg text-heading">
											افضل موقع تسوق بالجملة في الشرق الأوسط
										</p>
									</div>
									<ul className="contact-infor">
										<li>
											<img src="/imgs/theme/icons/icon-location.svg" alt="" />
											<strong>العنوان: </strong>{" "}
											<span>ش. القصر برج ناتكو الرازي الدور الثامن</span>
										</li>
										<li>
											<img src="/imgs/theme/icons/icon-contact.svg" alt="" />
											<strong>تواصل بنا: </strong>
											<span className="text-brand">101062030-1-967+</span>
										</li>
										<li>
											<img src="/imgs/theme/icons/icon-contact.svg" alt="" />
											<strong>تواصل بنا: </strong>
											<span className="text-brand">770770764-967+</span>
										</li>
										<li>
											<img src="/imgs/theme/icons/icon-contact.svg" alt="" />
											<strong>تواصل بنا: </strong>
											<span className="text-brand">734353333-967+</span>
										</li>
										<li>
											<img src="/imgs/theme/icons/icon-email-2.svg" alt="" />
											<strong>البريد الإلكتروني:</strong>
											<span>info@yatajjer.com</span>
										</li>
										<li>
											<img src="/imgs/theme/icons/icon-clock.svg" alt="" />
											<strong>الدوام:</strong>
											<span>08:00-20:00(GMT+3), ماعدا الجمعة-السبت</span>
										</li>
									</ul>
								</div>
							</div>
							<div
								className="footer-link-widget widget-install-app col wow animate__animated animate__fadeInUp"
								data-wow-delay=".5s"
							>
								<h4 className="widget-title">حمل التطبيق</h4>
								<p className="">قريباً على متجر جوجل و أبل</p>
								<div className="download-app">
									<a href="#" className="hover-up mb-sm-2 mb-lg-0">
										<img src="/imgs/theme/app-store.jpg" alt="" />
									</a>
									<a href="#" className="hover-up mb-sm-2">
										<img src="/imgs/theme/google-play.jpg" alt="" />
									</a>
								</div>
								<p className="mb-20">طرق الدفع</p>
								<img className="" src="/imgs/theme/payment-method.png" alt="" />
							</div>
						</div>
					</div>
				</section>
				<div
					className="container pb-30 wow animate__animated animate__fadeInUp"
					data-wow-delay="0"
				>
					<div className="row align-items-center">
						<div className="col-12 mb-30">
							<div className="footer-bottom"></div>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6">
							<p className="font-sm mb-0">
								&copy; 2024, <strong className="text-brand">ياتاجر</strong> -
								تصميم وبرمجة <br />
								كل الحقوق محفوظة
							</p>
						</div>
						<div className="col-xl-4 col-lg-6 text-center d-none d-xl-block">
							<div className="hotline d-lg-inline-flex mr-30">
								<img src="/imgs/theme/icons/phone-call.svg" alt="hotline" />
								<p>
									770770764<span>العمل 08:00 - 20:00</span>
								</p>
							</div>
							<div className="hotline d-lg-inline-flex">
								<img src="/imgs/theme/icons/phone-call.svg" alt="hotline" />
								<p>
									770770764<span>24/7 مركز الدعم</span>
								</p>
							</div>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 text-end d-none d-md-block">
							<div className="mobile-social-icon">
								<h6>Follow Us</h6>
								<a href="#">
									<img src="/imgs/theme/icons/icon-facebook-white.svg" alt="" />
								</a>
								<a href="#">
									<img src="/imgs/theme/icons/icon-twitter-white.svg" alt="" />
								</a>
								<a href="#">
									<img
										src="/imgs/theme/icons/icon-instagram-white.svg"
										alt=""
									/>
								</a>
								<a href="#">
									<img
										src="/imgs/theme/icons/icon-pinterest-white.svg"
										alt=""
									/>
								</a>
								<a href="#">
									<img src="/imgs/theme/icons/icon-youtube-white.svg" alt="" />
								</a>
							</div>
							<p className="font-sm">
								اشترك بقنوات التواصل الاجتماعي ليصلك كل جديد
							</p>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}
