export const DOMAIN_HOST = "https://yssapi.yatajjer.com/api/v1/";
//export const DOMAIN_HOST = 'https://localhost:7270/api/v1/';

export const URL_SIGN_IN = DOMAIN_HOST + "user/Login";
export const URL_SIGN_OUT = DOMAIN_HOST + "user/Logout";
export const URL_FORGET_REQUEST = DOMAIN_HOST + "user/Forget";
export const URL_RESET_REQUEST = DOMAIN_HOST + "user/Reset";
export const URL_USER_VALID = DOMAIN_HOST + "user/Validate";
export const URL_USER_INFO = DOMAIN_HOST + "user/MyInfo";

export const URL_CALENDER_LIST = DOMAIN_HOST + "GeneralInformation/Calender";
export const URL_COUNTRY_LIST = DOMAIN_HOST + "GeneralInformation/Country";
export const URL_CITY_LIST = DOMAIN_HOST + "GeneralInformation/City";
export const URL_REGISTER_TYPE_LIST = DOMAIN_HOST + "GeneralInformation/RegisterType";

export const URL_REGISTER_FORM_GENERAL = DOMAIN_HOST + "GeneralForm/Register";
