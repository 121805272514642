import React from "react";
import CalenderList from "../../common/Calender/CalenderList";

export default function MainPart() {
	return (
		<>
			<main className="main">
				<section className="home-slider position-relative mb-30">
					<div className="container">
						<div className="home-slide-cover mt-30">
							<div className="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1">
								<div
									className="single-hero-slider single-animation-wrap"
									style={{ backgroundImage: "url(/imgs/banner/banner-17.png)" }}
								>
									<div className="slider-content">
										<h1 className="display-2 mb-40">
											Don’t miss amazing
											<br />
											grocery deals
										</h1>
										<p className="mb-65">Sign up for the daily newsletter</p>
										<form className="form-subcriber d-flex">
											<input type="email" placeholder="Your emaill address" />
											<button className="btn" type="submit">
												Subscribe
											</button>
										</form>
									</div>
								</div>
								<div
									className="single-hero-slider single-animation-wrap"
									style={{ backgroundImage: "url(/imgs/banner/banner-18.png)" }}
								>
									<div className="slider-content">
										<h1 className="display-2 mb-40">
											Big Grocery
											<br />
											Big Discount
										</h1>
										<p className="mb-65">
											Save up to 10% off on your first order
										</p>
									</div>
								</div>
							</div>
							<div className="slider-arrow hero-slider-1-arrow"></div>
						</div>
					</div>
				</section>
				{/* <!--End hero slider--> */}
				<section className="popular-categories section-padding">
					<div className="container wow animate__animated animate__fadeIn">
						<div className="section-title">
							<div className="title">
								<h3>Daily Deals</h3>
							</div>
							<div
								className="slider-arrow slider-arrow-2 flex-right carausel-10-columns-arrow"
								id="carausel-10-columns-arrows"
							></div>
						</div>
						<div className="carausel-3-columns-cover position-relative">
							<div className="carausel-3-columns" id="carausel-10-columns">
								<div
									className="card-2 bg-9 wow animate__animated animate__fadeInUp"
									data-wow-delay=".1s"
								>
									<figure className="img-hover-scale overflow-hidden">
										<a href="/#">
											<img src="/imgs/product1/001.png" alt="" />
										</a>
									</figure>
									<h6>
										<a href="/#">Hide & Seek Fab</a>
									</h6>
								</div>
								<div
									className="card-2 bg-10 wow animate__animated animate__fadeInUp"
									data-wow-delay=".2s"
								>
									<figure className="img-hover-scale overflow-hidden">
										<a href="/#">
											<img src="/imgs/product1/002.png" alt="" />
										</a>
									</figure>
									<h6>
										<a href="/#">Murano Chocolate Chip Cookies</a>
									</h6>
								</div>
								<div
									className="card-2 bg-11 wow animate__animated animate__fadeInUp"
									data-wow-delay=".3s"
								>
									<figure className="img-hover-scale overflow-hidden">
										<a href="/#">
											<img src="/imgs/product1/003.png" alt="" />
										</a>
									</figure>
									<h6>
										<a href="/#">Happy Happy Choco Ship</a>
									</h6>
								</div>
								<div
									className="card-2 bg-12 wow animate__animated animate__fadeInUp"
									data-wow-delay=".4s"
								>
									<figure className="img-hover-scale overflow-hidden">
										<a href="/#">
											<img src="/imgs/product1/004.png" alt="" />
										</a>
									</figure>
									<h6>
										<a href="/#">Hide & Seek Fab!bon chocolate biscuits</a>
									</h6>
								</div>
								<div
									className="card-2 bg-13 wow animate__animated animate__fadeInUp"
									data-wow-delay=".5s"
								>
									<figure className="img-hover-scale overflow-hidden">
										<a href="/#">
											<img src="/imgs/product1/005.png" alt="" />
										</a>
									</figure>
									<h6>
										<a href="/#">Chtaura Valley Green Olive</a>
									</h6>
								</div>
								<div
									className="card-2 bg-14 wow animate__animated animate__fadeInUp"
									data-wow-delay=".6s"
								>
									<figure className="img-hover-scale overflow-hidden">
										<a href="/#">
											<img src="/imgs/product1/006.png" alt="" />
										</a>
									</figure>
									<h6>
										<a href="/#">Cho Cho Chocolate Vanilla</a>
									</h6>
								</div>
								<div
									className="card-2 bg-15 wow animate__animated animate__fadeInUp"
									data-wow-delay=".7s"
								>
									<figure className="img-hover-scale overflow-hidden">
										<a href="/#">
											<img src="/imgs/product1/007.png" alt="" />
										</a>
									</figure>
									<h6>
										<a href="/#">Hide & Seek Fab! Vanilla</a>
									</h6>
								</div>
								<div
									className="card-2 bg-12 wow animate__animated animate__fadeInUp"
									data-wow-delay=".8s"
								>
									<figure className="img-hover-scale overflow-hidden">
										<a href="/#">
											<img src="/imgs/product1/008.png" alt="" />
										</a>
									</figure>
									<h6>
										<a href="/#">Chtaura Valley </a>
									</h6>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <!--End category slider--> */}
				<section className="banners mb-25">
					<div className="container">
						<div className="row text-center card-2" style={{ height: "75px" }}>
							<div className="title">
								<h3>الأقسام الرئيسية</h3>
							</div>
						</div>
						<div className="row">
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div
									className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp"
									data-wow-delay="0"
								>
									<div className="product-img-action-wrap">
										<div
											className="product-img"
											style={{
												border: "solid",
												borderWidth: "0.2rem",
												borderColor: "#EDC515",
												borderRadius: "18px",
											}}
										>
											<a
												asp-controller="Home"
												asp-action="Customer"
												asp-route-id="1"
											>
												<img
													src="/imgs/category/003.png"
													style={{ width: "379px", height: "335px" }}
													alt=""
												/>
											</a>
										</div>
									</div>
									<div className="product-content-wrap">
										<div className="deals-content">
											<h2 className="text-center">
												<a
													asp-controller="Home"
													asp-action="Customer"
													asp-route-id="1"
												>
													مصانع وشركات المواد الغذائية
												</a>
											</h2>
											<div className="product-card-bottom">
												<a
													className="btn w-100 hover-up"
													asp-controller="Home"
													asp-action="Customer"
													asp-route-id="1"
												>
													المزيد{" "}
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div
									className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp"
									data-wow-delay="0"
								>
									<div className="product-img-action-wrap">
										<div
											className="product-img"
											style={{
												border: "solid",
												borderWidth: "0.2rem",
												borderColor: "#EDC515",
												borderRadius: "18px",
											}}
										>
											<a>
												<img
													src="/imgs/category/006.png"
													style={{ width: "379px", height: "335px" }}
													alt=""
												/>
											</a>
										</div>
									</div>
									<div className="product-content-wrap">
										<div
											className="deals-content"
											style={{ backgroundColor: "transparent" }}
										>
											<h2 className="text-center">
												<a> </a>
											</h2>
											<div className="product-card-bottom">
												<a className="btn w-100 hover-up">قريبا </a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div
									className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp"
									data-wow-delay="0"
								>
									<div className="product-img-action-wrap">
										<div
											className="product-img"
											style={{
												border: "solid",
												borderWidth: "0.2rem",
												borderColor: "#EDC515",
												borderRadius: "18px",
											}}
										>
											<a>
												<img
													src="/imgs/category/001.png"
													style={{ width: "379px", height: "335px" }}
													alt=""
												/>
											</a>
										</div>
									</div>
									<div className="product-content-wrap">
										<div
											className="deals-content"
											style={{ backgroundColor: "transparent" }}
										>
											<h2 className="text-center">
												<a></a>
											</h2>
											<div className="product-card-bottom">
												<a className="btn w-100 hover-up">قريبا </a>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-xl-3 col-lg-4 col-md-6">
								<div
									className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp"
									data-wow-delay="0"
								>
									<div className="product-img-action-wrap">
										<div
											className="product-img"
											style={{
												border: "solid",
												borderWidth: "0.2rem",
												borderColor: "#EDC515",
												borderRadius: "18px",
											}}
										>
											<a asp-controller="Home" asp-action="ServiceProvider">
												<img
													src="/imgs/category/serviceprovider.jpg"
													style={{ width: "379px", height: "335px" }}
													alt=""
												/>
											</a>
										</div>
									</div>
									<div className="product-content-wrap">
										<div className="deals-content">
											<h2 className="text-center">
												<a asp-controller="Home" asp-action="ServiceProvider">
													مزودين الخدمات التجارية
												</a>
											</h2>
											<div className="product-card-bottom">
												<a
													className="btn w-100 hover-up"
													asp-controller="Home"
													asp-action="ServiceProvider"
												>
													المزيد{" "}
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div
								className="row text-center card-2"
								style={{ height: "75px" }}
							>
								<div className="title">
									<h3>جريدة ياتاجر</h3>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <!--End banners--> */}
				<CalenderList />
			</main>
		</>
	);
}
