import React, { useEffect, useState } from "react";
import { URL_CALENDER_LIST } from "../../common/urlAPI";
import { URL_IMG_CALENDER } from "../../common/urlImages";

export default function CalenderList() {
	const [dataList, setDataList] = useState([]);

	useEffect(() => {
		GetData();
	}, []);

	const GetData = async (_e) => {
		var requestHeader = new Headers();
		requestHeader.append("Accept", "application/json");
		requestHeader.append("Content-Type", "application/json;charset=UTF-8");
		try {
			const response = await fetch(URL_CALENDER_LIST, {
				method: "GET",
				headers: requestHeader,
			});
			if (response.ok) {
				const result = await response.json();
				setDataList(result);
			}
		} catch {}
	};

	useEffect(() => {
		if (
			typeof window.initializeCarousel === "function" &&
			dataList.length > 0
		) {
			window.initializeCarousel("carausel-11-columns");
			window.startCountDown("eventcounter", "2024/10/10");
		}

		return () => {
			if (typeof window.destroyCarousel === "function") {
				window.destroyCarousel("carausel-11-columns");
			}
		};
	}, [dataList]);
	return (
		<>
			<section className="popular-categories section-padding">
				<div className="container wow animate__animated animate__fadeIn">
					<div className="section-title">
						<div className="title">
							<h3>احداث تاريخية قد تهمك</h3>
						</div>
						<div
							className="slider-arrow slider-arrow-2 flex-right carausel-10-columns-arrow"
							id="carausel-11-columns-arrows"
						></div>
					</div>
					<div className="carausel-3-columns-cover position-relative">
						<div className="carausel-3-columns" id="carausel-11-columns">
							{dataList.map((item) => (
								<div
									className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp"
									data-wow-delay=".3s"
									key={item.id}
								>
									<div className="product-img-action-wrap">
										<div className="product-img">
											<a href="shop-product-right.html">
												<img src={URL_IMG_CALENDER + item.id + ".png"} alt="" />
											</a>
										</div>
									</div>
									<div className="product-content-wrap">
										<div className="deals-countdown-wrap">
											<div
												className="deals-countdown eventcounter"
												data-countdown={item.eventDate}
											></div>
										</div>
										<div className="deals-content">
											<h2>
												<a href="shop-product-right.html">{item.eventTitle}</a>
											</h2>
											<div className="product-rate-cover">
												<div className="product-rate d-inline-block">
													<div
														className="product-rating"
														style={{ width: item.rating ? item.rating : "0%" }}
													></div>
												</div>
											</div>
											<div>
												<span className="font-small text-muted">
													اليوم <a href={item.ownerURL}>{item.eventDate}</a>
												</span>
											</div>
											<div>
												<span className="font-small text-muted">
													{item.overview}
												</span>
											</div>
											<div className="product-card-bottom">
												{item.ownerURL && (
													<div className="add-cart">
														<a className="add" href={item.ownerURL}>
															<i className="fi-rs-user-time mr-5"></i>الحدث
														</a>
													</div>
												)}
												{item.registrationURL && (
													<div className="add-cart">
														<a className="add" href={item.registrationURL}>
															<i className="fi-rs-calender mr-5"></i>
															التسجيل
														</a>
													</div>
												)}
												{item.preparationURL && (
													<div className="add-cart">
														<a className="add" href={item.preparationURL}>
															<i className="fi-rs-treatment mr-5"></i>
															المشغلين
														</a>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
