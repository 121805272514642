import { Link } from "react-router-dom";

export default function Login() {
	return (
		<>
		
			<main className="main pages">
				<div className="page-content pt-50 pb-50">
					<div className="container">
						<div className="row">
							<div className="col-xl-8 col-lg-10 col-md-12 m-auto">
								<div className="row">
									<div className="col-lg-6 pr-30 d-none d-lg-block">
										<img
											className="border-radius-15"
											src="/imgs/page/login-1.png"
											alt=""
										/>
									</div>
									<div className="col-lg-6 col-md-8">
										<div className="login_wrap widget-taber-content background-white">
											<div className="padding_eight_all bg-white">
												<div className="heading_s1">
													<h1 className="mb-5">تسجيل الدخول</h1>
													<p className="mb-30">
														ليس لديك حساب؟{" "}
														<a href="page-register.html">سجل من هنا</a>
													</p>
												</div>
												<form method="post">
													<div className="form-group">
														<input
															asp-for="Email"
															type="text"
															required=""
															name="email"
															placeholder="البريد الإلكتروني او اسم المستخدم *"
														/>
													</div>
													<div className="form-group">
														<input
															asp-for="Password"
															required=""
															type="password"
															name="password"
															placeholder="كلمة المرور *"
														/>
													</div>
													<div className="login_footer form-group mb-50">
														<a className="text-muted" href="#">
															نسيت كلمة المرور؟
														</a>
													</div>
													<div className="form-group">
														<button
															type="submit"
															className="btn btn-heading btn-block hover-up"
															name="login"
														>
															تسجيل الدخول
														</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
}
