import React from "react";

export default function MobileMenu() {
	return (
		<>
			<div className="mobile-header-active mobile-header-wrapper-style">
				<div className="mobile-header-wrapper-inner">
					<div className="mobile-header-top">
						<div className="mobile-header-logo">
							<a href="index.html">
								<img src="/logo.png" alt="logo" />
							</a>
						</div>
					</div>
					<div className="mobile-header-content-area">
						<div className="mobile-menu-wrap mobile-header-border">
							<nav>
								<ul className="mobile-menu font-heading">
									<li className="menu-item-has-children">
										<a href="#" style={{ width: "100%" }}>
											استعراض الاقسام<i className="fi-rs-angle-down"></i>
										</a>
										<ul className="dropdown">
											<li className="sub-mega-menu sub-mega-menu-width-22">
												<a className="menu-title" href="#">
													المواد الغذائية
												</a>
												<ul>
													<li>
														<a>الاساسية</a>
													</li>
													<li>
														<a>غير الاساسية</a>
													</li>
												</ul>
											</li>
											<li className="sub-mega-menu sub-mega-menu-width-22">
												<a className="menu-title" href="#">
													الزراعة والثروة الحيوانية
												</a>
												<ul>
													<li>
														<a>المدخلات الزراعية</a>
													</li>
													<li>
														<a>المنتجات الزراعية</a>
													</li>
												</ul>
											</li>
											<li className="sub-mega-menu sub-mega-menu-width-22">
												<a className="menu-title" href="#">
													الصحة
												</a>
												<ul>
													<li>
														<a>الادوية</a>
													</li>
													<li>
														<a>المستلزمات الطبية</a>
													</li>
													<li>
														<a>التجهيزات الطبية</a>
													</li>
													<li>
														<a>المنتجات التجميلية</a>
													</li>
													<li>
														<a>المكملات الغذائية</a>
													</li>
												</ul>
											</li>
											<li className="sub-mega-menu sub-mega-menu-width-22">
												<a className="menu-title" href="#">
													مزودين الخدمات التجارية
												</a>
												<ul>
													<li>
														<a>التمويل والاقراض</a>
													</li>
													<li>
														<a>الحوالات النقدية والمصرفية</a>
													</li>
													<li>
														<a>الخدمات اللوجستية</a>
													</li>
													<li>
														<a>بناء القدرات والتطوير والتاهيل</a>
													</li>
													<li>
														<a>اجراءات السفر والحجوزات</a>
													</li>
													<li>
														<a>الترجمة والمراسلات التجارية</a>
													</li>
													<li>
														<a>المعارض والفعاليات المحلية والدولية</a>
													</li>
													<li>
														<a>الخدمات الداعمة والبنى التحتية</a>
													</li>
												</ul>
											</li>
										</ul>
									</li>
									<li className="menu-item-has-children">
										<a href="#" style={{ width: "100%" }}>
											العروض <i className="fi-rs-angle-down"></i>
										</a>
										<ul className="dropdown">
											<li>
												<a>المنتجات</a>
											</li>
											<li>
												<a>الخدمات</a>
											</li>
											<li>
												<a>المناقصات</a>
											</li>
											<li>
												<a>فرص العمل</a>
											</li>
											<li>
												<a>فعاليات واحداث</a>
											</li>
											<li>
												<a>اخبار التجارة ورجال الاعمال</a>
											</li>
										</ul>
									</li>
									<li className="menu-item-has-children">
										<a href="#" style={{ width: "100%" }}>
											الأشهر <i className="fi-rs-angle-down"></i>
										</a>
										<ul className="dropdown">
											<li>
												<a>المواد الغذائية</a>
											</li>
											<li>
												<a>المواد الصحية والطبية</a>
											</li>
											<li>
												<a>المواد والمنتجات الزراعية</a>
											</li>
											<li>
												<a>الخدمات</a>
											</li>
										</ul>
									</li>
									<li className="menu-item-has-children">
										<a href="#" style={{ width: "100%" }}>
											جريدة يا تاجر <i className="fi-rs-angle-down"></i>
										</a>
										<ul className="dropdown">
											<li>
												<a>اخبار التجارة ورجال الاعمال</a>
											</li>
											<li>
												<a>اخبار التنمية المستدامة</a>
											</li>
											<li>
												<a>اخبار التغيرات المناخية</a>
											</li>
											<li>
												<a>فرص العمل الوظيفية</a>
											</li>
											<li>
												<a>المناقصات والمزادات</a>
											</li>
											<li>
												<a>قصص نجاح</a>
											</li>
											<li>
												<a>ارشاد وتوجيه</a>
											</li>
										</ul>
									</li>
									<li className="menu-item-has-children">
										<a href="#" style={{ width: "100%" }}>
											التعاون <i className="fi-rs-angle-down"></i>
										</a>
										<ul className="dropdown">
											<li>
												<a>كن بائعا</a>
											</li>
											<li>
												<a>الإنظمام للبرنامج</a>
											</li>
											<li>
												<a>افتج متجرك الخاص</a>
											</li>
										</ul>
									</li>
									<li className="menu-item-has-children">
										<a href="#" style={{ width: "100%" }}>
											التقويم <i className="fi-rs-angle-down"></i>
										</a>
										<ul className="dropdown">
											<li>
												<a>التجاري بشكل عام</a>
											</li>
											<li>
												<a>القطاع الصحي</a>
											</li>
											<li>
												<a>
													القطاع الزراعي<i className="fi-rs-angle-right"></i>
												</a>
												<ul className="level-menu">
													<li>
														<a>بحسب الدولة</a>
													</li>
													<li>
														<a>المحاصيل الزراعية</a>
													</li>
													<li>
														<a>الثروة الحيوانية</a>
													</li>
												</ul>
											</li>
											<li>
												<a>القطاع الانساني</a>
											</li>
											<li>
												<a>التقويم السنوى العام بحسب الدولة</a>
											</li>
											<li>
												<a>التقويم العام</a>
											</li>
										</ul>
									</li>
									<li className="menu-item-has-children">
										<a href="#" style={{ width: "100%" }}>
											مكتبة يا تاجر <i className="fi-rs-angle-down"></i>
										</a>
										<ul className="dropdown">
											<li>
												<a>دراسات / بحوث</a>
											</li>
											<li>
												<a>كتب</a>
											</li>
											<li>
												<a>ملخصات</a>
											</li>
											<li>
												<a>اهم روابط اعمال يا تاجر</a>
											</li>
											<li>
												<a>روابط برامج تدريبية</a>
											</li>
										</ul>
									</li>
									<li className="menu-item-has-children">
										<a href="#" style={{ width: "100%" }}>
											من نحن <i className="fi-rs-angle-down"></i>
										</a>
										<ul className="dropdown">
											<li>
												<a>بماذا نتميز</a>
											</li>
											<li>
												<a>
													خدماتنا<i className="fi-rs-angle-right"></i>
												</a>
												<ul className="level-menu">
													<li>
														<a>اشترك في معرض المنتجات</a>
													</li>
													<li>
														<a>اشترك في معرض الخدمات</a>
													</li>
													<li>
														<a>اعلن عن منتجك / خدمتك</a>
													</li>
													<li>
														<a>اعلن عن مناقصتك </a>
													</li>
													<li>
														<a>اعلن عن الفرص الوظيفية لديك</a>
													</li>
													<li>
														<a>اشترك في جريدة يا تاجر</a>
													</li>
													<li>
														<a>اشترك في أنظمة وتطبيقات يا تاجر</a>
													</li>
													<li>
														<a>الحملات الترويجية والاعلانية</a>
													</li>
													<li>
														<a>خدمات الاستشارات والتسويق والمبيعات</a>
													</li>
												</ul>
											</li>
											<li>
												<a>عملائنا المستهدفين</a>
											</li>
											<li>
												<a>الاتصال بنا</a>
											</li>
										</ul>
									</li>
								</ul>
							</nav>
						</div>
						<div className="mobile-social-icon mb-50">
							<h6 className="mb-15">انضم إلينا</h6>
							<a href="#">
								<img src="/imgs/theme/icons/icon-facebook-white.svg" alt="" />
							</a>
							<a href="#">
								<img src="/imgs/theme/icons/icon-twitter-white.svg" alt="" />
							</a>
							<a href="#">
								<img src="/imgs/theme/icons/icon-instagram-white.svg" alt="" />
							</a>
							<a href="#">
								<img src="/imgs/theme/icons/icon-pinterest-white.svg" alt="" />
							</a>
							<a href="#">
								<img src="/imgs/theme/icons/icon-youtube-white.svg" alt="" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
