import React from "react";

export default function Menu() {
	return (
		<>
			<div className="header-bottom header-bottom-bg-color sticky-bar">
				<div className="container">
					<div className="header-wrap header-space-between position-relative">
						<div className="logo logo-width-1 d-block d-lg-none">
							<a href="index.html">
								<img
									src="/logo.png"
									alt="logo"
									style={{ height: "50px", width: "100px", minWidth: "100px" }}
								/>
							</a>
						</div>
						<div className="header-nav d-none d-lg-flex">
							<div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
								<nav>
									<ul>
										<li className="position-static">
											<a href="#">
												استعراض الاقسام<i className="fi-rs-angle-down"></i>
											</a>
											<ul className="mega-menu">
												<li className="sub-mega-menu sub-mega-menu-width-22">
													<a className="menu-title" href="#">
														المواد الغذائية
													</a>
													<ul>
														<li>
															<a>الاساسية</a>
														</li>
														<li>
															<a>غير الاساسية</a>
														</li>
													</ul>
												</li>
												<li className="sub-mega-menu sub-mega-menu-width-22">
													<a className="menu-title" href="#">
														الزراعة والثروة الحيوانية
													</a>
													<ul>
														<li>
															<a>المدخلات الزراعية</a>
														</li>
														<li>
															<a>المنتجات الزراعية</a>
														</li>
													</ul>
												</li>
												<li className="sub-mega-menu sub-mega-menu-width-22">
													<a className="menu-title" href="#">
														الصحة
													</a>
													<ul>
														<li>
															<a>الادوية</a>
														</li>
														<li>
															<a>المستلزمات الطبية</a>
														</li>
														<li>
															<a>التجهيزات الطبية</a>
														</li>
														<li>
															<a>المنتجات التجميلية</a>
														</li>
														<li>
															<a>المكملات الغذائية</a>
														</li>
													</ul>
												</li>
												<li className="sub-mega-menu sub-mega-menu-width-22">
													<a className="menu-title" href="#">
														مزودين الخدمات التجارية
													</a>
													<ul>
														<li>
															<a>التمويل والاقراض</a>
														</li>
														<li>
															<a>الحوالات النقدية والمصرفية</a>
														</li>
														<li>
															<a>الخدمات اللوجستية</a>
														</li>
														<li>
															<a>بناء القدرات والتطوير والتاهيل</a>
														</li>
														<li>
															<a>اجراءات السفر والحجوزات</a>
														</li>
														<li>
															<a>الترجمة والمراسلات التجارية</a>
														</li>
														<li>
															<a>المعارض والفعاليات المحلية والدولية</a>
														</li>
														<li>
															<a>الخدمات الداعمة والبنى التحتية</a>
														</li>
													</ul>
												</li>
											</ul>
										</li>
										<li>
											<a>
												العروض <i className="fi-rs-angle-down"></i>
											</a>
											<ul className="sub-menu">
												<li>
													<a>المنتجات</a>
												</li>
												<li>
													<a>الخدمات</a>
												</li>
												<li>
													<a>المناقصات</a>
												</li>
												<li>
													<a>فرص العمل</a>
												</li>
												<li>
													<a>فعاليات واحداث</a>
												</li>
												<li>
													<a>اخبار التجارة ورجال الاعمال</a>
												</li>
											</ul>
										</li>
										<li>
											<a>
												الأشهر <i className="fi-rs-angle-down"></i>
											</a>
											<ul className="sub-menu">
												<li>
													<a>المواد الغذائية</a>
												</li>
												<li>
													<a>المواد الصحية والطبية</a>
												</li>
												<li>
													<a>المواد والمنتجات الزراعية</a>
												</li>
												<li>
													<a>الخدمات</a>
												</li>
											</ul>
										</li>
										<li>
											<a>
												جريدة يا تاجر <i className="fi-rs-angle-down"></i>
											</a>
											<ul className="sub-menu">
												<li>
													<a>اخبار التجارة ورجال الاعمال</a>
												</li>
												<li>
													<a>اخبار التنمية المستدامة</a>
												</li>
												<li>
													<a>اخبار التغيرات المناخية</a>
												</li>
												<li>
													<a>فرص العمل الوظيفية</a>
												</li>
												<li>
													<a>المناقصات والمزادات</a>
												</li>
												<li>
													<a>قصص نجاح</a>
												</li>
												<li>
													<a>ارشاد وتوجيه</a>
												</li>
											</ul>
										</li>
										<li>
											<a>
												التعاون <i className="fi-rs-angle-down"></i>
											</a>
											<ul className="sub-menu">
												<li>
													<a>كن بائعا</a>
												</li>
												<li>
													<a>الإنظمام للبرنامج</a>
												</li>
												<li>
													<a>افتج متجرك الخاص</a>
												</li>
											</ul>
										</li>
										<li>
											<a>
												التقويم <i className="fi-rs-angle-down"></i>
											</a>
											<ul className="sub-menu">
												<li>
													<a>التجاري بشكل عام</a>
												</li>
												<li>
													<a>القطاع الصحي</a>
												</li>
												<li>
													<a>
														القطاع الزراعي<i className="fi-rs-angle-right"></i>
													</a>
													<ul className="level-menu">
														<li>
															<a>بحسب الدولة</a>
														</li>
														<li>
															<a>المحاصيل الزراعية</a>
														</li>
														<li>
															<a>الثروة الحيوانية</a>
														</li>
													</ul>
												</li>
												<li>
													<a>القطاع الانساني</a>
												</li>
												<li>
													<a>التقويم السنوى العام بحسب الدولة</a>
												</li>
												<li>
													<a>التقويم العام</a>
												</li>
											</ul>
										</li>
										<li>
											<a>
												مكتبة يا تاجر <i className="fi-rs-angle-down"></i>
											</a>
											<ul className="sub-menu">
												<li>
													<a>دراسات / بحوث</a>
												</li>
												<li>
													<a>كتب</a>
												</li>
												<li>
													<a>ملخصات</a>
												</li>
												<li>
													<a>اهم روابط اعمال يا تاجر</a>
												</li>
												<li>
													<a>روابط برامج تدريبية</a>
												</li>
											</ul>
										</li>
										<li>
											<a>
												من نحن <i className="fi-rs-angle-down"></i>
											</a>
											<ul className="sub-menu">
												<li>
													<a>بماذا نتميز</a>
												</li>
												<li>
													<a>
														خدماتنا<i className="fi-rs-angle-right"></i>
													</a>
													<ul className="level-menu">
														<li>
															<a>اشترك في معرض المنتجات</a>
														</li>
														<li>
															<a>اشترك في معرض الخدمات</a>
														</li>
														<li>
															<a>اعلن عن منتجك / خدمتك</a>
														</li>
														<li>
															<a>اعلن عن مناقصتك </a>
														</li>
														<li>
															<a>اعلن عن الفرص الوظيفية لديك</a>
														</li>
														<li>
															<a>اشترك في جريدة يا تاجر</a>
														</li>
														<li>
															<a>اشترك في أنظمة وتطبيقات يا تاجر</a>
														</li>
														<li>
															<a>الحملات الترويجية والاعلانية</a>
														</li>
														<li>
															<a>خدمات الاستشارات والتسويق والمبيعات</a>
														</li>
													</ul>
												</li>
												<li>
													<a>عملائنا المستهدفين</a>
												</li>
												<li>
													<a>الاتصال بنا</a>
												</li>
											</ul>
										</li>
									</ul>
								</nav>
							</div>
						</div>
						<div className="hotline d-none d-lg-flex">
							<img src="/imgs/theme/icons/icon-headphone.svg" alt="hotline" />
							<p>
								967-1-101062030<span>مركز الدعم 24/7</span>
							</p>
						</div>
						<div className="header-action-icon-2 d-block d-lg-none">
							<div className="burger-icon burger-icon-white">
								<span className="burger-icon-top"></span>
								<span className="burger-icon-mid"></span>
								<span className="burger-icon-bottom"></span>
							</div>
						</div>
						<div className="header-action-right d-block d-lg-none">
							<div className="header-action-2">
								<div className="header-action-icon-2">
									<a href="shop-wishlist.html">
										<img alt="Nest" src="/imgs/theme/icons/icon-heart.svg" />
										<span className="pro-count white">4</span>
									</a>
								</div>
								<div className="header-action-icon-2">
									<a className="mini-cart-icon" href="#">
										<img alt="Nest" src="/imgs/theme/icons/icon-cart.svg" />
										<span className="pro-count white">2</span>
									</a>
									<div className="cart-dropdown-wrap cart-dropdown-hm2">
										<ul>
											<li>
												<div className="shopping-cart-img">
													<a href="shop-product-right.html">
														<img alt="Nest" src="/imgs/shop/thumbnail-3.jpg" />
													</a>
												</div>
												<div className="shopping-cart-title">
													<h4>
														<a href="shop-product-right.html">
															Plain Striola Shirts
														</a>
													</h4>
													<h3>
														<span>1 × </span>$800.00
													</h3>
												</div>
												<div className="shopping-cart-delete">
													<a href="#">
														<i className="fi-rs-cross-small"></i>
													</a>
												</div>
											</li>
											<li>
												<div className="shopping-cart-img">
													<a href="shop-product-right.html">
														<img alt="Nest" src="/imgs/shop/thumbnail-4.jpg" />
													</a>
												</div>
												<div className="shopping-cart-title">
													<h4>
														<a href="shop-product-right.html">
															Macbook Pro 2022
														</a>
													</h4>
													<h3>
														<span>1 × </span>$3500.00
													</h3>
												</div>
												<div className="shopping-cart-delete">
													<a href="#">
														<i className="fi-rs-cross-small"></i>
													</a>
												</div>
											</li>
										</ul>
										<div className="shopping-cart-footer">
											<div className="shopping-cart-total">
												<h4>
													Total <span>$383.00</span>
												</h4>
											</div>
											<div className="shopping-cart-button">
												<a href="shop-cart.html">View cart</a>
												<a href="shop-checkout.html">Checkout</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
