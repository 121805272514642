import { Link } from "react-router-dom";

export default function NotFound() {
	return (
		<>
			<main className="main page-404">
				<div className="page-content pt-150 pb-150">
					<div className="container">
						<div className="row">
							<div className="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
								<p className="mb-20">
									<img
										src="/imgs/page/page-404.png"
										alt=""
										className="hover-up"
									/>
								</p>
								<h1 className="display-2 mb-30">
									ماتبحث عنه غير متوفر حالياً؛ الرجاء التواصل مع المختصين او
									المحاولة في وقت آخر
								</h1>
								<Link
									className="btn btn-default submit-auto-width font-xs hover-up mt-30"
									to="/"
								>
									<i className="fi-rs-home mr-5"></i> العودة للرئيسية
								</Link>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
}
